<template>
  <BlokkliProvider
    v-if="homeConfig"
    v-bind="homeConfig.blokkliProps"
    edit-label="Edit stage"
    isolate
  >
    <BlokkliField
      name="field_paragraphs_stage"
      :list="homeConfig?.stageParagraphs"
      :class="containerClasses"
    />
  </BlokkliProvider>
  <div v-else :class="containerClasses">
    <ParagraphHomeStageTeaser
      v-for="(homeStageTeaser, index) in homeStageTeasers"
      v-bind="homeStageTeaser"
      :key="index"
    />
  </div>
</template>

<script lang="ts" setup>
import type { HomeStageTeaserProps } from '../Paragraph/HomeStageTeaser/index.vue'
import type { HomeConfigFragment } from '#graphql-operations'

const props = defineProps<{
  homeConfig?: HomeConfigFragment
  homeStageTeasers?: HomeStageTeaserProps[]
}>()

const containerClasses = computed(() => {
  let classes =
    'md:layout-grid home-stage relative mx-auto max-w-screen-xxxl bg-grey-light-03 mobile-only:m-16 md:mt-24 md:mx-32'

  if (
    props.homeConfig?.stageParagraphs?.length === 1 ||
    props.homeStageTeasers?.length === 1
  ) {
    // We only fix the height for single teaser version, since the other one has a large height an works with the sticky property to achive the scroll effect.
    //
    // 100dvh - navigation bar height - margin-top - margin-bottom
    // 100dvh - 72px - 16px - 16px => 100dvh - 104px
    classes += ' h-[calc(100dvh-104px)]'
  }

  return classes
})
</script>

<style lang="scss" scoped>
.home-stage,
:deep(.home-stage) {
  @screen md {
    height: unset;
  }
}
</style>
